export const userPaymentMethodsTransformer = (data) => {

    let formattedPaymentMethods = {
        "payment_methods" : []    
    }


    for(let i=0; i < data.length; i++){
       // 

        formattedPaymentMethods.payment_methods.push({	
                   
            "card_type" : data[i].brand,
            "card_digits" : data[i].last_4,
            "card_exp_date" : data[i].exp_month + "/" + data[i].exp_year,
            "payment_id" : data[i].uuid,
            "card_error" : null
        
        })
    }

    
    
return formattedPaymentMethods

}
