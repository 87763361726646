import { userProfileAPITransformer } from '../transformers/userProfileAPITransformer.js'
import { userLicenseInsuranceTransformer } from '../transformers/userLicenseInsurnaceTransformer.js'
import { userProfilePhotoTransformer } from '../transformers/userProfilePhotoTransformer.js'
import { userBasicInfoTransformer } from '../transformers/userBasicInfoTransformer.js'
import { reviewsFromRidersAPITransformer } from '../../bikes/calls/reviewFromRidersTransformer'
import { reviewsFromOwnersAPITransformer } from '../../bikes/calls/reviewFromOwnersTransformer'

export const USERS_CALLS = {
  'public-profile': {
    use_mock: false,
    mock_endpoint: 'account/U111AAA/public',
    api_type: 'GET',
    requires_auth: false,
    transFormData(data) {
      return userProfileAPITransformer(data)
    },
  },
  'wizard-check': {
    use_mock: false,
    mock_endpoint: 'users/wizard',
    api_type: 'GET',
    requires_auth: true,
  },
  'basic-info': {
    use_mock: false,
    mock_endpoint: 'account/U111AAA/edit-profile',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return userBasicInfoTransformer(data)
    },
  },
  'update-profile': {
    use_mock: false,
    mock_endpoint: 'account/update-profile',
    api_type: 'PUT',
    requires_auth: true,
  },
  'license-insurance': {
    use_mock: false,
    mock_endpoint: 'account/U111AAA/edit-documents',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return userLicenseInsuranceTransformer(data)
    },
  },
  'update-license-insurance': {
    use_mock: false,
    mock_endpoint: 'account/update-documents',
    api_type: 'PUT',
    requires_auth: true,
  },
  'update-profile-photo': {
    use_mock: false,
    mock_endpoint: 'account/update-profile-photo',
    api_type: 'PUT',
    requires_auth: true,
  },
  'profile-photo': {
    use_mock: false,
    mock_endpoint: 'account/U111AAA/edit-profile-photo',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      return userProfilePhotoTransformer(data)
    },
  },
  'user-detailed-info': {
    use_mock: false,
    mock_endpoint: 'users/detailed_info',
    api_type: 'GET',
    requires_auth: true,
  },
  'user-reviews': {
    use_mock: false,
    mock_endpoint: 'users/U111AAA/reviews',
    api_type: 'GET',
    requires_auth: true,
    transFormData(data) {
      if (data.from === 'riders') {
        return reviewsFromRidersAPITransformer(data.reviews)
      } else {
        return reviewsFromOwnersAPITransformer(data.reviews)
      }
    }
  },
  'rider-docs-request': {
    use_mock: false,
    mock_endpoint: 'request_docs/R111AAA',
    api_type: 'GET',
    requires_auth: true,
  },
  'rider-docs-upload': {
    use_mock: false,
    mock_endpoint: 'request_docs/R111AAA/upload',
    api_type: 'POST',
    requires_auth: true,
  },
  'rider-docs-upload-completed': {
    use_mock: false,
    mock_endpoint: 'request_docs/R222BBB/completed',
    api_type: 'POST',
    requires_auth: true,
  },
  'rider-vouched-progress-data': {
    use_mock: false,
    mock_endpoint: 'riders/data',
    api_type: 'POST',
    requires_auth: true
  }
}
